import { cookieState, ALLOW_COOKIES } from '@composables/useStorage.js'
import { ALLOWED_URLS } from '@config/game.js'
import { isObj } from '@helpers/utils.js'
import { i18n } from '@/src/main.js'

export default function () {
  window.addEventListener('message', (e) => {
    if (
      (ALLOWED_URLS.length && !ALLOWED_URLS.includes(e.origin)) ||
      !e.data ||
      !isObj(e.data)
    )
      return

    const { modioAllowCookies, modioLang } = e.data
    if (modioAllowCookies !== undefined) {
      const allow = ['1', 1, 'true', true].includes(modioAllowCookies)
      cookieState.value = allow ? ALLOW_COOKIES : 0
    }

    if (modioLang) {
      i18n.global.locale.value = modioLang
    }
  })
}

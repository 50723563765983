export const GAME_ID = 5653
export const PUBLIC_API_KEY = 'e54f0463f045e9fc5882ad093354961d'
export const PRIMARY_COLOR = '#ffbc3d'
export const DARK_COLOR = '#3350ff'
export const LIGHT_COLOR = '#F0F1F6'
export const DARK_MODE = false
export const TAG_FILTER = null
export const showLegal = false
export const hostURL = '*'
export const ALLOWED_URLS = []
